import { Link } from 'react-router-dom';
import { Button, Modal } from "react-bootstrap";
import { indicators } from '../indicators';

export default function SelectIndicatorModal({
    showSelectIndicatorModal,
    handleSelectIndicatorModalClose,
    handleSelectIndicator
}) {
    return (
        <Modal size="lg" show={showSelectIndicatorModal} onHide={handleSelectIndicatorModalClose}>
            <Modal.Header>
                <Modal.Title>Select an indicator</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-head-fixed text-nowrap condensed">
                    <thead>
                        <tr>
                            <th style={{ width: "75%" }}>Indicator</th>
                            <th>Scope</th>
                            <th>Info</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(indicators).map((key, index) => (
                            <tr key={'i-' + index}>
                                <td><Link onClick={() => handleSelectIndicator(key)}>{indicators[key].name}</Link></td>
                                <td>{indicators[key].scope}</td>
                                <td align="center"><Link title={indicators[key].description}><i className="fas fa-info"></i></Link></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleSelectIndicatorModalClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

