import React, { useEffect } from 'react';
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';
import { Button, Card, Nav, Tab } from "react-bootstrap";

import Plotly from '../components/BacktestGraph'
import OrdersTable from '../components/OrdersTable';
import PositionsTable from '../components/PositionsTable';
import { useEvaluateStrategyMutation } from "../hooks/useEvaluateStrategyMutation";

export default function BacktestForm({
  strategy,
  strategyIndicators
}) {
  const { data, error, isLoading, mutate: evaluateStrategy, isSuccess, isError } = useEvaluateStrategyMutation();

  const delayDate = 2
  const endDate = new Date(Date.now() - delayDate * 86400000).toISOString().slice(0, 10);
  const start1D = new Date(Date.now() - ((1 + delayDate) * 86400000)).toISOString().slice(0, 10);
  const start3D = new Date(Date.now() - ((3 + delayDate) * 86400000)).toISOString().slice(0, 10);
  const start1M = new Date(Date.now() - ((30 + delayDate) * 86400000)).toISOString().slice(0, 10);
  const start3M = new Date(Date.now() - ((90 + delayDate) * 86400000)).toISOString().slice(0, 10);
  const start6M = new Date(Date.now() - ((180 + delayDate) * 86400000)).toISOString().slice(0, 10);
  const start12M = new Date(Date.now() - ((365 + delayDate) * 86400000)).toISOString().slice(0, 10);

  const initialValues = {
    date_from: start1M,
    date_to: endDate,
    exchange: "binance",
    asset: "BTC-EUR",
    maximum_open_orders: 20,
    maximum_new_positions: 10,
    maximum_exposure: 1000,
    fees: 0.1,
    initial_balance: 1000,
    buy_amount_value: 100,
    buy_expiry_duration_enable: "disabled",
    buy_expiry_duration_frame: "hours",
    buy_expiry_duration_value: 1,
    cooldown_enable: "disabled",
    cooldown_frame: "seconds",
    cooldown_value: 5,
    sell_expiry_duration_enable: "disabled",
    sell_expiry_duration_frame: "hours",
    sell_expiry_duration_value: 1,
    take_profit_enable: "disabled",
    take_profit_value: 0.15,
    stop_loss_enable: "disabled",
    stop_loss_value: 0.1,
    trailing_stop_enable: "disabled",
    trailing_stop_value: 0.05,
    mm_chart_period: "5m",
    mm_order_sequence: "buy_first",
    mm_order_positioning: "pip",
    mm_order_positioning_value: 1,
    mm_minimum_spread_activation: "any",
    mm_minimum_spread_value: 0,
    mm_buy_amount_value: 100,
    mm_sell_amount_value: 100,
    mm_cooldown_enable: "disabled",
    mm_cooldown_frame: "seconds",
    mm_cooldown_value: 5
  };

  const validationSchema = Yup.object().shape({
  });

  const handleSubmit = async (submitValues) => {
    const values = {
      ...strategy,
      indicators: strategyIndicators,
      strategyBacktest: submitValues
    };
    evaluateStrategy(values);
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success('Backtest completed!');
      console.log(data);
    } else if (isError) {
      toast.error('Backtest error!');
      console.log(error);
    } else return;
  }, [isSuccess, isError, data, error]);

  let btSignals = [];
  let plotTraces = [];
  let chartData = {};
  if (isSuccess && data) {
    const btData = data.data ? data.data.map(d => {
      return {
        x: new Date(d.timestamp),
        open: d.open,
        high: d.high,
        low: d.low,
        close: d.close,
        volume: d.volume
      }
    }) : [];

    btSignals = data.signals ? data.signals.map(d => {
      return {
        x: new Date(d.timestamp),
        y: d.price,
        arrowcolor: d.side === "buy" ? "green" : "red",
        hovertext: d.type + " signal<br>date:" + new Date(d.timestamp).toLocaleString() + "<br>price:" + d.price + "<br>",
        ax: 0,
        ay: d.side === "buy" ? 30 : -30,
        showarrow: true, arrowhead: 2, arrowwidth: 2
      }
    }) : [];

    const trace_count = data.traces ? data.traces.length : 0;
    for (let i = 0; i < trace_count; i++) {
      const trace = data.traces[i].data.map(d => {
        return {
          x: d.datetime,
          title: data.traces[i].type,
          type: 'scatter',
          values: d.value,
          posx: 'x',
          posy: 'y'
        }
      });
      plotTraces[i] = trace[0];
    }

    chartData = {
      signals: btSignals,
      cart1: {
        x: btData.map(kline => kline.x),
        low: btData.map(kline => kline.low),
        open: btData.map(kline => kline.open),
        high: btData.map(kline => kline.high),
        close: btData.map(kline => kline.close),
        type: 'candlestick',
        posx: 'x',
        posy: 'y'
      },
      cart2: {
        type: 'bar',
        title: 'volume',
        values: btData.map(kline => kline.volume),
        posx: 'x2',
        posy: 'y2'
      },
      cart3: {
        type: 'scatter',
        title: 'macd',
        values: [115.75, 115.809998, 116.470001, 117.940002, 118.300003, 118.599998, 118.209999, 118.809998, 118.220001, 119.709999, 119.370003, 119.730003, 119.769997, 119.5, 120.279999, 121.599998, 121.599998, 120.660004, 120.620003, 127.010002, 127.779999, 128.160004, 128.899994, 130.449997, 131.220001, 131.119995, 132.050003, 132.75, 133.25, 134.619995],
        posx: 'x',
        posy: 'y3'
      },
      cart4: {
        type: 'scatter',
        title: 'sma',
        values: [115.75, 115.809998, 116.470001, 117.940002, 118.300003, 118.599998, 118.209999, 118.809998, 118.220001, 119.709999, 119.370003, 119.730003, 119.769997, 119.5, 120.279999, 121.599998, 121.599998, 120.660004, 120.620003, 127.010002, 127.779999, 128.160004, 128.899994, 130.449997, 131.220001, 131.119995, 132.050003, 132.75, 133.25, 134.619995],
        posx: 'x',
        posy: 'y'
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <Tab.Container defaultActiveKey="backtesting">
              <Card>
                <Card.Header>
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="backtesting">Backtesting</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card.Header>
                <Card.Body>
                  <Tab.Content>
                    <Tab.Pane eventKey="backtesting">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Time select</label>
                            <div className="col-sm-8">
                              <div className="row">
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start1D); }} className="btn btn-default" title='1 Day'>1D</button></div>
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start3D); }} className="btn btn-default" title='3 Days'>3D</button></div>
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start1M); }} className="btn btn-default" title='1 Month'>1M</button></div>
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start3M); }} className="btn btn-default" title='3 Months'>3M</button></div>
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start6M); }} className="btn btn-default" title='6 Months'>6M</button></div>
                                <div className="col-sm-2"><button type="button" onClick={() => { setFieldValue('date_from', start12M); }} className="btn btn-default" title='12 Months'>12M</button></div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row y-0">
                            <label className="col-sm-4 col-form-label">Date</label>
                            <div className="col-sm-4">
                              <Field name="date_from" className="form-control" placeholder="From" />
                            </div>
                            <div className="col-sm-4">
                              <Field name="date_to" className="form-control" placeholder="To" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <Button type="submit" size="lg" variant="primary" className="float-right"><i className="fas fa-play"></i> Start Backtesting</Button>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="settings">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Exchange</label>
                            <div className="col-sm-5">
                              <Field className="form-control" as="select" name="exchange">
                                <option value="binance">BINANCE</option>
                              </Field>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Asset</label>
                            <div className="col-sm-5">
                              <Field className="form-control" as="select" name="asset">
                                <option value="BTC-EUR">BTC-EUR</option>
                                <option value="BTC-USDT">BTC-USDT</option>
                                <option value="ETH-BTC">ETH-BTC</option>
                              </Field>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Maximum open orders</label>
                            <div className="col-sm-5">
                              <Field name="maximum_open_orders" className="form-control" type="number" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Maximum new positions</label>
                            <div className="col-sm-5">
                              <Field name="maximum_new_positions" className="form-control" type="number" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Fees</label>
                            <div className="col-sm-5">
                              <Field name="fees" className="form-control" type="number" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Initial balance</label>
                            <div className="col-sm-5">
                              <Field name="initial_balance" className="form-control" type="number" />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">Maximum exposure</label>
                            <div className="col-sm-5">
                              <Field name="maximum_exposure" className="form-control" type="number" />
                            </div>
                          </div>
                        </div>
                      </div>

                      {strategy.type === "indicators" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card card-success">
                              <div className="card-header">
                                <div className="card-title">
                                  <h5>BUY SETTINGS</h5>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Buy amount</label>
                                  <div className="col-sm-6">
                                    <Field name="buy_amount_value" className="form-control" type="number" />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Expiry Duration</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="buy_expiry_duration_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="buy_expiry_duration_frame">
                                          <option value="seconds">Seconds</option>
                                          <option value="minutes">Minutes</option>
                                          <option value="hours">Hours</option>
                                          <option value="days">Days</option>
                                          <option value="weeks">Weeks</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field name="buy_expiry_duration_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Cooldown</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="cooldown_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="cooldown_frame">
                                          <option value="seconds">Seconds</option>
                                          <option value="minutes">Minutes</option>
                                          <option value="hours">Hours</option>
                                          <option value="days">Days</option>
                                          <option value="weeks">Weeks</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field name="cooldown_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">&nbsp;</label>
                                  <div className="col-sm-6">
                                    &nbsp;
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card card-danger">
                              <div className="card-header">
                                <div className="card-title">
                                  <h5>SELL SETTINGS</h5>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Expiry Duration</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="sell_expiry_duration_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="sell_expiry_duration_frame">
                                          <option value="seconds">Seconds</option>
                                          <option value="minutes">Minutes</option>
                                          <option value="hours">Hours</option>
                                          <option value="days">Days</option>
                                          <option value="weeks">Weeks</option>
                                        </Field>
                                      </div>
                                      <div className="col-4">
                                        <Field name="sell_expiry_duration_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Take Profit</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="take_profit_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-8">
                                        <Field name="take_profit_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Stop Loss</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="stop_loss_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-8">
                                        <Field name="stop_loss_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-sm-4 col-form-label">Trailing Stop Loss</label>
                                  <div className="col-sm-6">
                                    <div className="row">
                                      <div className="col-4">
                                        <Field className="form-control" as="select" name="trailing_stop_enable">
                                          <option value="disabled">Disabled</option>
                                          <option value="enabled">Enabled</option>
                                        </Field>
                                      </div>
                                      <div className="col-8">
                                        <Field name="trailing_stop_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {strategy.type === "marketmaker" && (
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card card-primary">
                              <div className="card-header">
                                <div className="card-title">
                                  <h5>MARKET MAKER SETTINGS</h5>
                                </div>
                              </div>
                              <div className="card-body">
                                <div className='row'>
                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Candel Size</label>
                                      <div className="col-sm-6">
                                        <div className="row">
                                          <div className="col-12">
                                            <Field className="custom-select rounded-0" as="select" name="mm_chart_period">
                                              <option value="1m">1 minute</option>
                                              <option value="5m">5 minutes</option>
                                              <option value="15m">15 minutes</option>
                                              <option value="30m">30 minutes</option>
                                              <option value="1h">1 hour</option>
                                              <option value="2h">2 hours</option>
                                              <option value="4h">4 hours</option>
                                              <option value="1d">1 day</option>
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Order Sequence</label>
                                      <div className="col-sm-6">
                                        <div className="row">
                                          <div className="col-12">
                                            <Field className="form-control" as="select" name="mm_order_sequence">
                                              <option value="buy_sell_both">Buy and Sell at the same time</option>
                                              <option value="buy_first">Buy first, then Sell </option>
                                              <option value="sell_first">Sell first, then Buy </option>
                                            </Field>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Order positioning</label>
                                      <div className="col-sm-6">
                                        <div className="row">
                                          <div className="col-8">
                                            <Field className="form-control" as="select" name="mm_order_positioning">
                                              <option value="fixed">Some pip better Ask / Bid</option>
                                              <option value="perc">Percentage Ask / Bid</option>
                                            </Field>
                                          </div>
                                          <div className="col-4">
                                            <Field name="mm_order_positioning_value" className="form-control" type="number" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Minimum spread activation</label>
                                      <div className="col-sm-6">
                                        <div className="row">
                                          <div className="col-8">
                                            <Field className="form-control" as="select" name="mm_minimum_spread_activation">
                                              <option value="any">Any</option>
                                              <option value="pip">Pip between Ask / Bid</option>
                                              <option value="perc">% between Ask / Bid</option>
                                            </Field>
                                          </div>
                                          <div className="col-4">
                                            <Field name="mm_minimum_spread_value" className="form-control" type="number" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Buy amount</label>
                                      <div className="col-sm-6">
                                        <Field name="mm_buy_amount_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Sell amount</label>
                                      <div className="col-sm-6">
                                        <Field name="mm_sell_amount_value" className="form-control" type="number" />
                                      </div>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-4 col-form-label">Cooldown</label>
                                      <div className="col-sm-6">
                                        <div className="row">
                                          <div className="col-4">
                                            <Field className="form-control" as="select" name="mm_cooldown_enable">
                                              <option value="disabled">Disabled</option>
                                              <option value="enabled">Enabled</option>
                                            </Field>
                                          </div>
                                          <div className="col-4">
                                            <Field className="form-control" as="select" name="mm_cooldown_frame">
                                              <option value="seconds">Seconds</option>
                                              <option value="minutes">Minutes</option>
                                              <option value="hours">Hours</option>
                                              <option value="days">Days</option>
                                              <option value="weeks">Weeks</option>
                                            </Field>
                                          </div>
                                          <div className="col-4">
                                            <Field name="mm_cooldown_value" className="form-control" type="number" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </Card>
            </Tab.Container>
          </Form>
        )}
      </Formik>

      {isSuccess && data && (
        <Tab.Container defaultActiveKey="stats">
          <Card>
            <Card.Header>
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="stats">Stats</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="terminal">Terminal</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="positions">Positions</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orders">Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="graph">Graph</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <Tab.Content>
                <Tab.Pane eventKey="stats">
                  <div className="row text-sm dark-mode">
                    <ul className="col-md-6">
                      <li className="d-flex"><span className="h5">Overview</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Realized Profit/Loss</span><span className="text-right">{data.stats.realized_profit_loss}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Unrealized Profit/Loss</span><span className="text-right">{data.stats.unrealized_profit_loss}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Average Loss per Losing Trade</span><span className="text-right">{data.stats.average_loss_losing_trade}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Average Profit per Winning Trade</span><span className="text-right">{data.stats.average_profit_winning_trade}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Best Trade Profit</span><span className="text-right">{data.stats.best_trade_profit}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Best Trade Return</span><span className="text-right">{(data.stats.best_trade_return * 100).toFixed(2)}%</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Worst Trade Loss</span><span className="text-right">{data.stats.worst_trade_loss}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Worst Trade Return</span><span className="text-right">{(data.stats.worst_trade_return * 100).toFixed(2)}%</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Cumulative Fees (Quoted)</span><span className="text-right">{data.stats.cumulative_fees}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Number of Trades</span><span className="text-right">{data.stats.trades_number}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Number of Winning Trades</span><span className="text-right">{data.stats.number_winning_trade}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Number of Losing Trades</span><span className="text-right">{data.stats.number_losing_trade}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Portfolio Value (Quoted)</span><span className="text-right">{data.stats.portfolio_value}</span></li>
                    </ul>
                    <ul className="col-md-6">
                      <li className="d-flex"><span className="h5">Stats</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Gain &amp; Pain Ratio</span><span className="text-right">{data.stats.gain_pain} </span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Maximum Drawdown</span><span className="text-right">{data.stats.maximum_drawdown}%</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Normalized CVaR  (99)</span><span className="text-right">{data.stats.cvar99}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">ROMDD</span><span className="text-right">{data.stats.romdd}%</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Sharpe Ratio</span><span className="text-right">{data.stats.sharpe_ratio}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Time under Water</span><span className="text-right">{data.stats.tuw}</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Total Return</span><span className="text-right">{data.stats.total_return}%</span></li>
                      <li className="d-flex justify-content-between border-bottom"><span className="text-left">Market Trend</span><span className="text-right">{data.stats.market_trend}%</span></li>
                    </ul>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="terminal">
                  <pre className="dark-mode">
                    {data.terminal}
                  </pre>
                </Tab.Pane>
                <Tab.Pane eventKey="positions">
                  {data.positions && (
                    <div className="dark-mode">
                    <PositionsTable positions={data.positions} />
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="orders">
                  {data.orders && (
                    <div className="dark-mode">
                    <OrdersTable orders={data.orders} />
                    </div>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="graph">
                  {data.data && (
                    <Plotly data={chartData} traces={plotTraces} labels={{ x: "", y: "" }} />
                  )}
                </Tab.Pane>
              </Tab.Content>
            </Card.Body>
          </Card>
        </Tab.Container>
      )}
      {isLoading &&
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <section className="col-md-12 hidden">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="spinner-border spinner-border-lg align-center"></div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>
      }
    </>
  );
}
