import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { useQueryClient } from 'react-query';
import { useStrategiesQuery } from "../hooks/useStrategiesQuery";
import { useDeleteStrategyMutation } from "../hooks/useDeleteStrategyMutation";
import { toast } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';

function Strategy(props) {
  const { id, name, description, created, updated } = props.strategy;
  const { isLoading, isSuccess, isError, mutate: doDeleteStrategy, error } = useDeleteStrategyMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateStrategy = (strategyId) => {
    navigate('/strategies/edit/' + strategyId);
  };

  const deleteStrategy = (strategyId) => {
    if (window.confirm('Are you sure?')) {
      doDeleteStrategy(strategyId);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries('strategies');
      toast.success('Strategy successfully deleted');
    }

    if (isError) toast.error(error.message);
  },
    [isSuccess, isError, queryClient, error]
  );

  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{description}</td>
      <td>{created}</td>
      <td>{updated}</td>
      <td className="project-actions text-right">
        <Button onClick={() => updateStrategy(id)} size="sm"><i className="fas fa-pencil-alt"></i> Edit</Button>
        &nbsp;&nbsp;
        <Button onClick={() => deleteStrategy(id)} size="sm" variant="danger">
          {isLoading ?
            (<span className="spinner-border spinner-border-sm"></span>)
            :
            (
              <>
                <i className="fas fa-trash"></i> Delete
              </>
            )
          }
        </Button>
      </td>
    </tr>
  )
}

export default function Strategies() {
  const { data: strategies, isLoading, isSuccess, isError, error } = useStrategiesQuery();
  const navigate = useNavigate();

  const addStrategy = () => {
    navigate('/strategies/add');
  };

  if (isError) {
    toast.error(error.message);
  }

  return (
    <>
      <Header />
      <Menu />
      <div className="dark-mode">
        <div className="content-wrapper min-height-800">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Strategies</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active">Strategies</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Small boxes (Stat box) */}
              {/* /.row */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-edit mr-1"></i> Strategies
                      </h3>
                      <div className="card-tools">
                        <Button onClick={() => addStrategy()} size="sm"><i className="fas fa-plus"></i> Add</Button>
                      </div>
                    </div>{/* /.card-header */}
                    <div className="card-body p-0">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Strategy Name</th>
                            <th>Description</th>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isSuccess && strategies.map((strategy, index) => (
                            <Strategy key={'s-' + index} strategy={strategy} />
                          ))}
                          {isLoading &&
                            <tr>
                              <td colSpan="6" className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                              </td>
                            </tr>
                          }
                          {isSuccess && !strategies.length &&
                            <tr>
                              <td colSpan="6" className="text-center">
                                <div className="p-2">No strategies to display</div>
                              </td>
                            </tr>
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                  {/* /.card */}
                </section>
              </div>
              {/* /.row (main row) */}
            </div>{/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>
      <Footer />
    </>
  );
}

