import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

export default function History() {
  const [dateState, useDateState] = useState(new Date());

  return (
    <>
      <Header />
      <Menu />
      <div className='dark-mode'>
        <div className="content-wrapper min-height-800">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Trade History</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Trade History</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          <section className="content">
            <div className="container-fluid">
            </div>{/* /.container-fluid */}
          </section>

        </div>
      </div>

      <Footer />
    </>
  );
}

