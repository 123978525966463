
import { ProtectedApi } from './ProtectedApi';

const getStrategies = async () => {
    const response = await ProtectedApi.get('/strategies');
    return response.data;
}

const getStrategy = async (strategyId) => {
    const response = await ProtectedApi.get(`/strategies/${strategyId}`);
    return response.data;
}

const addStrategy = async (data) => {
    const response = await ProtectedApi.post('/strategies', data);
    return response.data;
}

const updateStrategy = async (strategyId, data) => {
    const response = await ProtectedApi.put(`/strategies/${strategyId}`, data);
    return response.data;
}

const deleteStrategy = async (strategyId) => {
    const response = await ProtectedApi.delete(`/strategies/${strategyId}`);
    return response;
}

export {
    getStrategies,
    getStrategy,
    addStrategy,
    updateStrategy,
    deleteStrategy,
} 