import { PublicApi } from './PublicApi';
import { ProtectedApi } from './ProtectedApi';

const login = async (username, password) => {
    const response = await PublicApi.post('/user/login', { username, password });
    return response.data;
}

const refreshToken = async () => {
    const response = await ProtectedApi.post('/user/refreshToken');
    return response.data;
}

export {
    login,
    refreshToken,
} 