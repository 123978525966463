import React from 'react';

const PositionsTable = ({ positions }) => (
 
  <table className='table responsive striped'>
    <thead>
      <tr>
        <th>#</th>        
        <th>Asset</th>        
        <th>Entrytime</th>       
        <th>Price</th>
        <th>Exposure</th>
        <th>ExitTime</th>
        <th>ExitPrice</th>
        <th>Profit</th>
      </tr>
    </thead>
    <tbody>
      {positions.map((pos, index) => (
        <tr key={pos.open_id}>
          <td>{index + 1}</td>          
          <td>{pos.asset}</td>
          <td>{pos.entry_time}</td>
          <td>{pos.entry_price}</td>
          <td>{pos.exposure}</td>
          <td>{pos.exit_time}</td>
          <td>{pos.exit_price}</td>
          <td>{pos.profit}</td>
          </tr>          
      ))}
    </tbody>
    
  </table>
);

export default PositionsTable;
