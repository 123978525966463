
import { ProtectedApi } from './ProtectedApi';

const evaluateStrategy = async (data) => {
    const response = await ProtectedApi.post('/backtests/evaluateStrategy', data);
    return response.data;
}

const terminal = async (strategyUUID) => {
    const response = await ProtectedApi.get(`/backtests/terminal/${strategyUUID}`);
    return response.data;
}

export {
    evaluateStrategy,
    terminal,
} 