import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

export default function Dashboard() {
  const [dateState, useDateState] = useState(new Date());

  return (
    <>
      <Header />
      <Menu />
      <div className="dark-mode">
        <div className="content-wrapper min-height-800">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Dashboard</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard</li>
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              {/* Small boxes (Stat box) */}
              <div className="row">
                <div className="col-lg-3 col-6">
                  {/* small box */}
                  <div className="small-box bg-info">
                    <div className="inner">
                      <h3>0</h3>
                      <p>Today orders at {dateState.toLocaleDateString('en-GB', {
                        hour: 'numeric',
                        minute: 'numeric',
                      })}</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-bag" />
                    </div>
                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                  </div>
                </div>
                {/* ./col */}
                <div className="col-lg-3 col-6">
                  {/* small box */}
                  <div className="small-box bg-success">
                    <div className="inner">
                      <h3>0.00000000 BTC</h3>
                      <p>Curren profit </p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-stats-bars" />
                    </div>
                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                  </div>
                </div>
                {/* ./col */}
                <div className="col-lg-3 col-6">
                  {/* small box */}
                  <div className="small-box bg-warning">
                    <div className="inner">
                      <h3>0</h3>
                      <p>Closed orders</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-person-add" />
                    </div>
                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                  </div>
                </div>
                {/* ./col */}
                <div className="col-lg-3 col-6">
                  {/* small box */}
                  <div className="small-box bg-danger">
                    <div className="inner">
                      <h3>0</h3>
                      <p>Active bots</p>
                    </div>
                    <div className="icon">
                      <i className="ion ion-pie-graph" />
                    </div>
                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                  </div>
                </div>
                {/* ./col */}
              </div>
              {/* /.row */}
              {/* Main row */}
              <div className="row">
                {/* Left col */}
                <section className="col-lg-7 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        Open positions <span className="badge badge-info right">0</span>
                      </h3>
                      <div className="card-tools">
                        <button type="submit" title='Refresh' className="btn btn-xs btn-default">
                          <i className="fas fa-sync-alt"></i>
                        </button>
                      </div>
                    </div>{/* /.card-header */}
                    <div className="card-body table-responsive p-0">
                      <div className="tab-content p-0">

                        <table className="table table-head-fixed text-nowrap">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Bot</th>
                              <th>Currency</th>
                              <th>Amount</th>
                              <th>Cost</th>
                              <th>Result</th>
                              <th>Age</th>
                              <th>Action</th>
                            </tr>
                          </thead>
{/*                          
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><i class="btn-xs nav-icon fas fa-lock-open"></i>
                                &nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><i class="btn-xs nav-icon gray fas fa-lock-open"></i>&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><i class="btn-xs nav-icon gray fas fa-lock-open"></i>
                                &nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><a href="#" ><i class="btn-xs nav-icon gray fas fa-lock-open"></i></a><input type="button" class="btn btn-xs btn-danger" value="Sell" />&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><a href="#" ><i class="btn-xs nav-icon gray fas fa-lock-open"></i></a><input type="button" class="btn btn-xs btn-danger" value="Sell" />&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>6</td>
                              <td>Fast 30M</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><a href="#" ><i class="btn-xs nav-icon gray fas fa-lock-open"></i></a><input type="button" class="btn btn-xs btn-danger" value="Sell" />&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>7</td>
                              <td>Market Maker +-5%</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><a href="#" ><i class="btn-xs nav-icon gray fas fa-lock-open"></i></a><input type="button" class="btn btn-xs btn-danger" value="Sell" />&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Fast 30M</td>
                              <td>DASH</td>
                              <td>7.18</td>
                              <td>0.0867692</td>
                              <td><span class="red">-3.62%</span></td>
                              <td>2 days</td>
                              <td><a href="#" ><i class="btn-xs nav-icon gray fas fa-lock-open"></i></a><input type="button" class="btn btn-xs btn-danger" value="Sell" />&nbsp;<input type="button" class="btn btn-xs btn-info" value="Info" /></td>
                            </tr>
                          </tbody>
*/}                          
                        </table>
                      </div>
                    </div>{/* /.card-body */}
                  </div>
                  {/* /.card */}
                </section>
                {/* /.Left col */}
                {/* right col (We are only adding the ID to make the widgets sortable)*/}
                <section className="col-lg-5 connectedSortable">
                  {/* Map card */}
                  <div className="card">
                    <div className="card-header border-0">
                      <h3 className="card-title">
                        <i className="fas fa-map-marker-alt mr-1" />
                        Portfolio
                      </h3>
                    </div>
                    <div className="card-body table-responsive p-0">
                      {/*<div id="world-map" style={{height: 250, width: '100%'}} />*/}

                      {/* /.card-body*/}
                      <div className="tab-content p-0">
                      <table className="table table-striped table-valign-middle">
                        <thead>
                          <tr>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Cost</th>
                            <th>Result</th>
                          </tr>
                        </thead>
{/*                        
                        <tbody>
                          <tr>
                            <td>BTC</td>
                            <td>3.8</td>
                            <td>3,8 BTC</td>
                            <td>
                              <small class="text-success mr-1"><i class="fas fa-arrow-up"></i> 0,34%</small>
                            </td>
                          </tr>
                          <tr>
                            <td>ETH</td>
                            <td>16.21</td>
                            <td>1,2 BTC</td>
                            <td>
                              <small class="text-danger mr-1"><i class="fas fa-arrow-down"></i> 1,01%</small>
                            </td>
                          </tr>
                          <tr>
                            <td>DASH</td>
                            <td>351.73</td>
                            <td>1 BTC</td>
                            <td><small class="text-danger mr-1"><i class="fas fa-arrow-down"></i> 3.08%</small>
                            </td>
                          </tr>
                          <tr>
                            <td>AVAX</td>
                            <td>741.90</td>
                            <td>0,6 BTC</td>
                            <td>
                              <small class="text-danger mr-1"><i class="fas fa-arrow-up"></i> 1,23%</small>
                            </td>
                          </tr>
                          <tr>
                            <td>XRP</td>
                            <td>5000</td>
                            <td>0,5 BTC</td>
                            <td>
                              <small class="text-success mr-1"><i class="fas fa-arrow-up"></i> 3,25%</small>
                            </td>
                          </tr>
                        </tbody>
*/}                        
                      </table>
                    </div>
                    </div>
                  </div>
                  {/* /.card */}
                </section>
                {/* right col */}
              </div>
              {/* /.row (main row) */}
            </div>{/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>
      </div>

      <Footer />
    </>
  );
}

