import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';

import { useQueryClient } from 'react-query';
/*
import { useBotsQuery } from "../hooks/useBotsQuery";
import { useDeleteBotMutation } from "../hooks/useDeleteBotMutation";
*/
import { toast } from 'react-hot-toast';
import Button from 'react-bootstrap/Button';



function Bot(props) {
  const { id, name, status, exchange, asset, created, updated } = props.bot;
  //const { isLoading, isSuccess, isError, mutate: doDeleteBot, error } = useDeleteBotMutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateBot = (botId) => {
    navigate('/bots/edit/' + botId);
  };

  /*
  const deleteBot = (botId) => {
    if (window.confirm('Are you sure?')) {
      doDeleteBot(botId);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries('bots');
      toast.success('Bot successfully deleted');
    }

    if (isError) toast.error(error.message);
  },
    [isSuccess, isError, queryClient, error]
  );
*/
  return (
    <tr>
      <td>{id}</td>
      <td>{name}</td>
      <td>{exchange}<br/>{asset}</td>
      <td>{status}</td>
      <td>{created}</td>
      <td>{updated}</td>
      <td className="project-actions text-right">
        {/*
        <Button onClick={() => updateBot(id)} size="sm"><i className="fas fa-pencil-alt"></i> Edit</Button>
        &nbsp;&nbsp;
        <Button onClick={() => deleteBot(id)} size="sm" variant="danger">
          {isLoading ?
            (<span className="spinner-border spinner-border-sm"></span>)
            :
            (
              <>
                <i className="fas fa-trash"></i> Delete
              </>
            )
          }
        </Button>
        */}
      </td>
    </tr>
  )
}

export default function Bots() {
  /*
  const { data: bots, isLoading, isSuccess, isError, error } = useBotsQuery();
  const navigate = useNavigate();

  const addBot = () => {
    navigate('/bots/add');
  };

  if (isError) {
    toast.error(error.message);
  }
*/
  return (
  <>
  <Header />
  <Menu />
  <div className="dark-mode">
  <div className="content-wrapper min-height-800">
    {/* Content Header (Page header) */}
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h1 className="m-0 text-dark">Bots</h1>
          </div>{/* /.col */}
          <div className="col-sm-6">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item"><a href="#">Home</a></li>
              <li className="breadcrumb-item active">Bots</li>
            </ol>
          </div>{/* /.col */}
        </div>{/* /.row */}
      </div>{/* /.container-fluid */}
    </div>
    {/* /.content-header */}
    {/* Main content */}
    <section className="content">
      <div className="container-fluid">
        {/* Small boxes (Stat box) */}

        <div className="row">
                {/* Left col */}
                <section className="col-lg-12 connectedSortable">
                  {/* Custom tabs (Charts with tabs)*/}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        <i className="fas fa-edit mr-1"></i> Bots
                      </h3>
                      <div className="card-tools">
{/*
                        <Button onClick={() => addBot()} size="sm"><i className="fas fa-plus"></i> Add</Button>
*/}
                      </div>
                    </div>{/* /.card-header */}
                    <div className="card-body p-0">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Bot Name</th>
                            <th>Exchange-Asset</th>
                            <th>Status</th>
                            <th>Created</th>
                            <th>Updated</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
{/*                          
                          {isSuccess && bots.map((bot, index) => (
                            <Bot key={'s-' + index} bot={bot} />
                          ))}
                          {isLoading &&
                            <tr>
                              <td colSpan="7" className="text-center">
                                <div className="spinner-border spinner-border-lg align-center"></div>
                              </td>
                            </tr>
                          }
                          {isSuccess && !bots.length &&
                            <tr>
                              <td colSpan="7" className="text-center">
                                <div className="p-2">No bots to display</div>
                              </td>
                            </tr>
                          }
*/}                          
                        </tbody>
                      </table>
                    </div>

                  </div>
                  {/* /.card */}
                </section>
              </div>

      </div>{/* /.container-fluid */}
    </section>
    {/* /.content */}
  </div>
</div>

<Footer />
</>
        );
    }

