
import axios from 'axios';
import { useStore } from '../store';

const token = useStore.getState().token;
const clearStore = useStore.getState().clear;

const ProtectedApi = axios.create({
    baseURL: '/api',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    },
});

ProtectedApi.interceptors.response.use((response) => response, (error) => {
    if (error.response.status === 401) {
        clearStore();
        window.location = '/login';
    }
});

export {
    ProtectedApi
}