const indicators = {
  bb: {
    name: "Bollinger Bands",
    description: "Bollinger Bands are a type of price envelope developed by John Bollinger. (Price envelopes define upper and lower price range levels.) Bollinger Bands are envelopes plotted at a standard deviation level above and below a simple moving average of the price. Because the distance of the bands is based on standard deviation, they adjust to volatility swings in the underlying price. Bollinger Bands use 2 parameters, Period and Standard Deviations, StdDev. The default values are 20 for period, and 2 for standard deviations, although you may customize the combinations. Bollinger bands help determine whether prices are high or low on a relative basis. They are used in pairs, both upper and lower bands and in conjunction with a moving average. Further, the pair of bands is not intended to be used on its own. Use the pair to confirm signals given with other indicators.",
    scope: "volatility",
    chart_period_visible: true,
    ohlcv_visible: true,
    fast_period_visible: false,
    fast_period_default: 0,
    slow_period_visible: false,
    slow_period_default: 0,
    signal_period_visible: false,
    signal_period_default: 0,
    period_visible: true,
    period_default: 20,
    ma_type_visible: false,
  },
  macd: {
    name: "Moving Average Convergence Divergence (MACD)",
    description: "Moving average convergence/divergence (MACD, or MAC-D) is a trend-following momentum indicator that shows the relationship between two exponential moving averages (EMAs) of a security’s price. The MACD line is normally calculated by subtracting the 26-period EMA from the 12-period EMA.",
    scope: "trend",
    chart_period_visible: true,
    ohlcv_visible: true,
    fast_period_visible: true,
    fast_period_default: 12,
    slow_period_visible: true,
    slow_period_default: 21,
    signal_period_visible: true,
    signal_period_default: 9,
    period_visible: false,
    period_default: 0,
    ma_type_visible: false,
  },
  ma: {
    name: "Moving Average Crossover",
    description: "Moving average: SMA, EMA, DEMA, TRIX, TRIMA ",
    scope: "trend",
    chart_period_visible: true,
    ohlcv_visible: true,
    fast_period_visible: true,
    fast_period_default: 20,
    slow_period_visible: true,
    slow_period_default: 30,
    signal_period_visible: false,
    signal_period_default: 0,
    period_visible: false,
    period_default: 0,
    ma_type_visible: true,
  },
  momentum: {
    name: "Momentum",
    description: "Moving average: SMA, EMA, DEMA, TRIX, TRIMA ",
    scope: "momentum",
    chart_period_visible: true,
    ohlcv_visible: true,
    fast_period_visible: false,
    fast_period_default: 0,
    slow_period_visible: false,
    slow_period_default: 0,
    signal_period_visible: false,
    signal_period_default: 0,
    period_visible: true,
    period_default: 10,
    ma_type_visible: false,
  }

};

export {
  indicators
}