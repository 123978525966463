import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory'




const Plotly = (props) => {

  const {data, datatraces, labels} = props;


  const test = createPlotlyComponent()
  

  var trace_0 = {
  
    x: data.cart1.x, 
    low: data.cart1.low, 
    open: data.cart1.open, 
    close: data.cart1.close, 
    high: data.cart1.high, 

    increasing: {line: {color: '#B0D7E7'}}, 
    decreasing: {line: {color: '#ECB5B1'}}, 
    line: {color: 'rgba(31,119,180,1)'}, 
    type: 'candlestick', 
    xaxis: data.cart1.posx, 
    yaxis: data.cart1.posy,
    row: 1,
    col: 1,
  };

  var trace_1 = {
    x: data.cart1.x, 
    y:  data.cart2.values, 
    xaxis: data.cart1.posx, 
    yaxis: data.cart2.posy,
    marker: {color: '#B0D7E7'}, 
    type: data.cart2.type,
    row: 2,
    col: 1,

  };

   

    var trace1 = {
  
      x: data.cart1.x, 
      low: data.cart1.low, 
      open: data.cart1.open, 
      close: data.cart1.close, 
      high: data.cart1.high, 

      increasing: {line: {color: '#B0D7E7'}}, 
      decreasing: {line: {color: '#ECB5B1'}}, 
      line: {color: 'rgba(31,119,180,1)'}, 
      type: 'candlestick', 
      xaxis: data.cart1.posx, 
      yaxis: data.cart1.posy,
      row: 1,
      col: 1,
    };
    
    var trace2 = {
      x: data.cart1.x, 
      y:  data.cart2.values, 
      xaxis: data.cart1.posx, 
      yaxis: data.cart2.posy,
      marker: {color: '#B0D7E7'}, 
      type: data.cart2.type,
      row: 2,
      col: 1,

    };
    
    var trace3 = {
      x: data.cart1.x, 
      y:  data.cart3.values, 
      xaxis: data.cart1.posx, 
      yaxis: data.cart3.posy,
      marker: {color: '#B0D7E7'},
      line: { width: 1 },
      type: data.cart3.type,
      row: 3,
      col: 1,

    };
    
    var trace4 = {
      x: data.cart1.x, 
      y:  data.cart3.values, 
      xaxis: data.cart1.posx, 
      yaxis: data.cart1.posy,
      marker: {color: '#606060'},
      line: { width: 1 },
      type: 'scatter',
      mode: 'line',
      row: 1,
      col: 1,
    };

    var signal = data.signals;

    

    var traces = [trace1, trace2, trace3];
    

    var layout = {
      bgcolor: '#E9E9E9',
      paper_bgcolor: '#E9E9E9',
      plot_bgcolor: '#E9E9E9',
      showlegend: false,
      width: 1280,
      height: 1024,
      vertical_spacing:0.1,
      shared_xaxes: true,

    xaxis: {
      autorange: true,      
      rangeslider: {
        visible: false
      },      
      rangeselector: {
        font : { sixe: 13},
        buttons: [
          {
            step: "month",
            count: 1,
            label: "1 mo",
            stepmode: "backward"
          },
          {
            step: "month",
            count: 3,
            label: "3 mo",
            stepmode: "backward"
          },
          {
            step: "year",
            count: 1,
            label: "1yr",
            stepmode: "backward"
          },
          {
            "step": "all"
          },
          {
            step: "all",
            count: 1,
            label: "reset"
          }

        ]    
      }      
    },
    yaxis1: {
      autorange:true,
      domain:[0.5,1]
    },
    yaxis2: {
      autorange:true,
      domain:[0.3,0.49]
    },
    yaxis3: {
      autorange:true,
      domain:[0,0.29]
    },
    row_heights: [0.6, 0.2],  
    grid: {
        rows: 3,
        columns: 1,
        },
        annotations: signal,
          
    };      


  var config = {
    displayModeBar: false,
    scrollZoom: true,
    rangeslider: false,
  }


    

    return (
      <Plot      
        data={traces}
        layout={layout}
        config={config}
        useresizeHandler
      />


    );
  };
  
  export default Plotly;