import React, { useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../hooks/useLoginMutation";
import { useStore } from '../store';

export default function Login() {
  const navigate = useNavigate();
  const setToken = useStore((state) => state.setToken);
  const { data, error, isLoading, mutate: doLogin, isSuccess, isError } = useLoginMutation();

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  useEffect(() => {
    if (isSuccess) {
      setToken(data.token);
      navigate('/');
    } else if (isError) {
      toast.error('Login failed!');
      console.log(error);
    } else return;
  }, [isSuccess, isError, setToken, navigate, data, error]);

  const handleSubmit = async (formValues) => {
    doLogin(formValues);
  }

  return (
    <>
      <div className="dark-mode dark-mode-login vh-100 d-flex justify-content-center">
        <div className="form-access my-auto">
          <div className="mb-1">
            <img src={'/dist/img/lilith-login.png'} alt="Logo Lilith" />
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
            <span className="h1">&nbsp;</span>
              <span className="">Sign In</span>
              <div className="form-group">
                <Field name="username" type="text" className="form-control text-white" placeholder="Username" />
                <ErrorMessage
                  name="username"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <div className="form-group">
                <Field name="password" type="password" className="form-control text-white" placeholder="Password" />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="alert alert-danger"
                />
              </div>
              <button type="submit" className="btn btn-block" style={{"borderColour":"#ECE978", "backgroundColor":"#ECE978"}}>
                <div className="center">
                  {isLoading ?
                    (<span className="spinner-border spinner-border-sm"></span>)
                    :
                    (<span >Login</span>)
                  }
                </div>
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}
