import React from 'react';

const OrdersTable = ({ orders }) => (

  <table className='table responsive striped'>
    <thead>
      <tr>
        <th>#</th>
        <th>Datetime</th>
        <th>Asset</th>
        <th>Type</th>
        <th>Side</th>
        <th>Price</th>
        <th>Quantity</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      {orders.map(order => (
        <tr key={order.id}>
          <td>{order.id}</td>
          <td>{order.datetime}</td>
          <td>{order.asset}</td>
          <td>{order.type}</td>
          <td>{order.side}</td>
          <td>{order.price}</td>
          <td>{order.quantity}</td>
          <td>{order.status}</td>
        </tr>
      ))}
    </tbody>
    
  </table>
);

export default OrdersTable;
