import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import toast from 'react-hot-toast';
import { Button, Card, Badge } from "react-bootstrap";

import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import SelectIndicatorModal from '../components/SelectIndicatorModal';
import IndicatorFormModal from '../components/IndicatorFormModal';
import BacktestForm from '../components/BacktestForm';
import { useAddStrategyMutation } from "../hooks/useAddStrategyMutation";
import { useUpdateStrategyMutation } from "../hooks/useUpdateStrategyMutation";
import { getStrategy } from "../services/strategy";
import { useStore } from '../store';
import { indicators } from '../indicators';

export default function Strategy() {
  const userId = useStore((state) => state.id);
  const { id } = useParams();
  const isAddMode = !id;

  const { mutate: addStrategy, isSuccess: addIsSuccess, isError: addIsError, error: addError } = useAddStrategyMutation();
  const { mutate: updateStrategy, isSuccess: updateIsSuccess, isError: updateIsError, error: updateError } = useUpdateStrategyMutation(id);

  const [showSelectIndicatorModal, setShowSelectIndicatorModal] = useState(false);
  const handleSelectIndicatorModalClose = () => setShowSelectIndicatorModal(false);
  const handleSelectIndicatorModalShow = () => setShowSelectIndicatorModal(true);

  const [showIndicatorFormModal, setShowIndicatorFormModal] = useState(false);
  const handleIndicatorFormModalClose = () => setShowIndicatorFormModal(false);
  const handleIndicatorFormModalShow = () => setShowIndicatorFormModal(true);
  
  const [indicatorFormMode, setIndicatorFormMode] = useState('add');
  const [selectedIndicator, setSelectedIndicator] = useState('');
  const [selectedStrategyIndicator, setSelectedStrategyIndicator] = useState('');
  const [strategyIndicators, setStrategyIndicators] = useState([]);

  const handleSelectIndicator = (indicator) => {
    setSelectedIndicator(indicator);
    handleSelectIndicatorModalClose();
    setIndicatorFormMode('add');
    handleIndicatorFormModalShow();
  }

  const changeMandatoryIndicator = (index) => {
    setStrategyIndicators(strategyIndicators.map((si, i) => {
      if (i === index) {
        delete si.id;
        delete si.strategy_id;
        delete si.created;
        delete si.updated;
        si.mandatory = si.mandatory === 1 ? 0 : 1;
      }
      return si;
    }));
  }

  const updateIndicator = (index) => {
    setSelectedIndicator(strategyIndicators[index].type);
    setIndicatorFormMode('edit');
    setSelectedStrategyIndicator(index);
    handleIndicatorFormModalShow();
  }

  const duplicateIndicator = (index) => {
    const newStrategyIndicator = {};
    setStrategyIndicators(strategyIndicators => [...strategyIndicators, Object.assign(newStrategyIndicator, strategyIndicators[index])]);
  }

  const deleteIndicator = (index) => {
    setStrategyIndicators(strategyIndicators.filter((_, i) => i !== index));
  }

  const [showBacktest, setShowBacktest] = React.useState(false);

  const formRef = useRef();

  const initialValues = {
    name: "",
    type: "indicators",
    description: "",
    minimum_buy_signal: 1,
    minimum_sell_signal: 1,
    user_id: userId
  };

  const [strategyValues, setStrategyValues] = useState(initialValues);

  const [strategy, setStrategy] = useState(initialValues);

  useEffect(() => {
    if(!isAddMode) {
      getStrategy(id).then(strategy => {
        setStrategy(strategy);
        if(strategy.indicators) {
          setStrategyIndicators(strategy.indicators);
        }
      });
    }
  }, [id, isAddMode]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required!"),
    description: Yup.string(),
  });

  const handleSubmit = async (submitValues) => {
    const values = {
      ...submitValues,
      indicators: strategyIndicators
    };
    if(isAddMode) {
      addStrategy(values);
    }
    else {
      updateStrategy(values);
    }
  }

  useEffect(() => {
    if (addIsSuccess) {
      toast.success('Strategy successfully added!');
    } else if (addIsError) {
      toast.error('Cannot add strategy!');
      console.log(addError);
    } else return;
  }, [addIsSuccess, addIsError, addError]);

  useEffect(() => {
    if (updateIsSuccess) {
      toast.success('Strategy successfully updated!');
    } else if (updateIsError) {
      toast.error('Cannot update strategy!');
      console.log(updateError);
    } else return;
  }, [updateIsSuccess, updateIsError, updateError]);

  return (
    <>
      <Header />
      <Menu />
      <div className="dark-mode">
        <div className="content-wrapper min-height-800">
          {/* Content Header (Page header) */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0 text-dark">Strategy editor</h1>
                </div>{/* /.col */}
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                    <li className="breadcrumb-item active"><Link to="/strategies">Strategies</Link></li>
                    {isAddMode ? (
                      <li className="breadcrumb-item active">Add strategy</li>
                    ) : (
                      <li className="breadcrumb-item active">Edit strategy</li>
                    )}
                    
                  </ol>
                </div>{/* /.col */}
              </div>{/* /.row */}
            </div>{/* /.container-fluid */}
          </div>
          {/* /.content-header */}
          {/* Main content */}
          <section className="content">
            <Formik
              innerRef={formRef}
              initialValues={strategy}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                values,
              }) => {
                // Hack ==> https://stackoverflow.com/a/61070604
                setTimeout(() => setStrategyValues(values), 0);

                return (
                  <Form>
                    <Field type="hidden" name="user_id" />

                    <div className="row d-flex align-items-stretch">
                      <div className="col-md-3">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Strategy</h3>
                          </div>
                          <div className="card-body">
                            <div className="form-group">
                              <Field name="name" className="form-control" placeholder="Name" />
                              <ErrorMessage id="nameError" name="name" component="div" className="alert alert-danger" />
                            </div>
                            <div className="form-group">
                              <Field id="type" className="form-control" as="select" name="type" >
                                <option value="indicators">Indicators</option>
                                <option value="marketmaker">Market Maker</option>
                              </Field>
                            </div>
                            <div className="form-group">
                              <Field name="description" className="form-control" component="textarea" rows="4" placeholder="Description" />
                              <ErrorMessage id="descriptionError" name="description" component="div" className="alert alert-danger" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-9">
                        <Card>
                          <Card.Header>
                            <Button type="submit" size="sm" variant="success"><i className="fas fa-save"> </i> Save</Button>
                            &nbsp;
                            <Button onClick={() => setShowBacktest(!showBacktest)} size="sm" variant="info"><i className="fas fa-play"> </i> Backtest</Button>
                          </Card.Header>

                          {values.type === 'indicators' && (
                            <>
                              <Card.Body>
                                <div className="mailbox-controls">
                                  <button type="button" className="btn btn-default btn-sm checkbox-toggle"><i className="far fa-square"></i></button>
                                  &nbsp;
                                  <button type="button" className="btn btn-default btn-sm"><i className="far fa-trash-alt"></i></button>
                                  &nbsp;
                                  <button type="button" className="btn btn-default btn-sm"><i className="far fa-copy"></i></button>
                                  <Button onClick={handleSelectIndicatorModalShow} className=" float-right" size="sm" variant="primary"><i className="fas fa-bullhorn"> </i> Select Indicators</Button>
                                  &nbsp;
                                </div>

                                <div className="table-responsive mailbox-messages">
                                  <table className="table table-hover table-striped">
                                    <thead>
                                      <tr>
                                        <th>&nbsp;</th>
                                        <th>Type</th>
                                        <th>Indicator</th>
                                        <th>Candle size</th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {strategyIndicators.map((indicator, index) => (
                                        <tr key={'si' + index}>
                                          <td>
                                            <div className="icheck-primary">
                                              <input type="checkbox" value="" id={"indicator_check_" + index} />
                                              <label htmlFor={"indicator_check_" + index}></label>
                                            </div>
                                          </td>
                                          <td className="mailbox-star"><button className={`btn btn-xs ${indicator.side === 'buy' ? "btn-success" : "btn-danger"}`}>{indicator.side}</button></td>
                                          <td className="mailbox-name">{indicators[indicator.type].name}</td>
                                          <td className="mailbox-subject">{indicator.chart_period}</td>
                                          <td className="mailbox-attachment">
                                            <Button onClick={() => changeMandatoryIndicator(index)} size="xs" variant={indicator.mandatory === 1 ? "primary" : "default"}><i className={`fas ${indicator.mandatory === 1 ? "fa-lock" : "fa-unlock"}`}></i></Button>
                                            <Button onClick={() => updateIndicator(index)} size="xs" variant="default"><i className="fas fa-cog"></i></Button>
                                            <Button onClick={() => duplicateIndicator(index)} size="xs" variant="default"><i className="fas fa-copy"></i></Button>
                                            <Button onClick={() => deleteIndicator(index)} size="xs" variant="default"><i className="fas fa-trash"></i></Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </table>
                                </div>
                              </Card.Body>
                              <Card.Footer>
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <b>Actions</b><br />
                                      Select the minimum requirements for buy and sell actions of the strategy above.
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="card card-outline">
                                      <div className="card-body">
                                        <div className="form-inline">
                                          Minimum&nbsp;<Badge bg="success">BUY</Badge>&nbsp;signals:&nbsp;&nbsp;
                                          <Field name="minimum_buy_signal" className="form-control inline col-2" type="number" />&nbsp;&nbsp;out of {strategyIndicators.filter(strategyIndicator => strategyIndicator.side !== 'sell').length} signals.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="card card-outline">
                                      <div className="card-body">
                                        <div className="form-inline">
                                          Minimum&nbsp;<Badge bg="danger">SELL</Badge>&nbsp;signals:&nbsp;&nbsp;
                                          <Field name="minimum_sell_signal" className="form-control inline col-2" type="number" />&nbsp;&nbsp;out of {strategyIndicators.filter(strategyIndicator => strategyIndicator.side !== 'buy').length} signals.
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Card.Footer>
                            </>
                          )}
                        </Card>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </section>

          {showBacktest && (
            <section className="content">
              <BacktestForm
                strategy={strategyValues}
                strategyIndicators={strategyIndicators}
              />
            </section>
          )}
        </div>
      </div>

      <SelectIndicatorModal
        showSelectIndicatorModal={showSelectIndicatorModal}
        handleSelectIndicatorModalClose={handleSelectIndicatorModalClose}
        handleSelectIndicator={handleSelectIndicator}
      />

      <IndicatorFormModal
        indicator={selectedIndicator}
        showIndicatorFormModal={showIndicatorFormModal}
        handleIndicatorFormModalClose={handleIndicatorFormModalClose}
        indicatorFormMode={indicatorFormMode}
        strategyIndicators={strategyIndicators}
        selectedStrategyIndicator={selectedStrategyIndicator}
        setStrategyIndicators={setStrategyIndicators}
      />

      <Footer />
    </>
  );
}

