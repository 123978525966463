import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Nav } from "react-bootstrap";
import { useStore } from '../store';

export default function Header() {
  const navigate = useNavigate();
  const store = useStore();

  const logout = () => {
    store.clearStore();
    navigate('/login');
  };

  return (
    <div className="dark-mode">
      <nav className="main-header navbar navbar-expand navbar-light bg-gray">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link"><i className="fas fa-bars" /></Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link">Home</Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <Nav className="navbar-nav ml-auto">
          <Dropdown className="header-img-icon">
            <Dropdown.Toggle variant="default">
              <i className="fas fa-user"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/login" className="nav-link red" onClick={() => logout()}>
                      <i className="icon ion-md-power"></i>
                      <span>Log Out</span>
                    </Link>
                  </li>
                </ul>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </nav>
    </div>
  )
}
