import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

export default class Menu extends Component {
  render() {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          <Link to="/" className="brand-link">
            <img src="/dist/img/lilith.png" alt="Lilith Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
            <span className="brand-text font-weight-light">Lilith</span>
          </Link>
          <div className="sidebar">
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item">
                  <NavLink to="/" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" /> <p>Dashboard</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/strategies" className="nav-link">
                    <i className="nav-icon fas fa-edit"></i> <p>Strategies</p>
                  </NavLink>
                </li>
                <li className="nav-item user-panel">
                  <NavLink to="/bots" className="nav-link">
                    <i className="nav-icon fas fa-copy"></i> <p>Bots</p>
                  </NavLink>
                </li>
                <li className="nav-header">General data</li>
                <li className="nav-item">
                  <NavLink to="/stats" className="nav-link">
                    <i className="nav-icon fas fa-chart-bar" /> <p>Trade stats</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/charts" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" /> <p>Trade charts</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/history" className="nav-link">
                    <i className="nav-icon fas fa-table" /> <p>Trade history</p>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    )
  }
}
