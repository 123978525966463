import React, { useRef } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";

import { indicators } from '../indicators';

export default function IndicatorFormModal({
  indicator,
  showIndicatorFormModal,
  handleIndicatorFormModalClose,
  indicatorFormMode,
  strategyIndicators,
  selectedStrategyIndicator,
  setStrategyIndicators
}) {
  const initialValues = {
    type: indicator,
    side: "buy",
    chart_period: "1h",
    fast_period: indicator ? indicators[indicator].fast_period_default : 12,
    slow_period: indicator ? indicators[indicator].slow_period_default : 26,
    signal_period: indicator ? indicators[indicator].signal_period_default : 9,
    period: indicator ? indicators[indicator].period_default : 20,
    ma_type: "ema",
    input: "close",
    keep_signal: 0,
    mandatory: 0
  };

  const validationSchema = Yup.object().shape({
  });

  const handleSubmit = (formValues) => {
    if(indicatorFormMode === 'add') {
      setStrategyIndicators(strategyIndicators => [...strategyIndicators, formValues]);
    }
    else {
      setStrategyIndicators(strategyIndicators.map((indicator, i) => {
        if (i === selectedStrategyIndicator) {
          indicator = formValues;
        }
        return indicator;
      }));
    }
    handleIndicatorFormModalClose();
  }

  const formRef = useRef();

  return (
    <Modal size="lg" show={showIndicatorFormModal} onHide={handleIndicatorFormModalClose}>
      <Modal.Header>
        <Modal.Title>{indicator && indicators[indicator].name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="col-12">
          <div className="card card-info">
            <Formik
              innerRef={formRef}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              initialValues={indicatorFormMode === 'add' ? initialValues : strategyIndicators[selectedStrategyIndicator]}
            >
              {({
                setFieldValue
              }) => {
                return (
                  <Form>
                    <div className="card-body">
                      {indicator && indicators[indicator].chart_period_visible ? (
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Chart period</label>
                          <div className="col-sm-10">
                            <Field className="custom-select rounded-0" as="select" name="chart_period">
                              <option value="1m">1 minute</option>
                              <option value="5m">5 minutes</option>
                              <option value="15m">15 minutes</option>
                              <option value="30m">30 minutes</option>
                              <option value="1h">1 hour</option>
                              <option value="2h">2 hours</option>
                              <option value="4h">4 hours</option>
                              <option value="1d">1 day</option>
                            </Field>
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="chart_period" />
                      )}

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Signal</label>
                        <div className="col-sm-10">
                          <Field className="custom-select rounded-0" as="select" name="side">
                            <option value="buy">BUY</option>
                            <option value="sell">SELL</option>
                          </Field>
                        </div>
                      </div>

                      {indicator && indicators[indicator].ohlcv_visible ? (
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">OHLCV Value</label>
                          <div className="col-sm-10">
                            <Field className="custom-select rounded-0" as="select" name="input">
                              <option value="open">Open</option>
                              <option value="close">Close</option>
                              <option value="high">High</option>
                              <option value="low">Low</option>
                              <option value="volume">Volume</option>
                            </Field>
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="input" />
                      )}

                      {indicator && indicators[indicator].fast_period_visible ? (
                        <div className="form-group row">
                          <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Fast Period</label>
                          <div className="col-sm-10">
                            <Field name="fast_period" className="form-control" type="number" />
                            <ErrorMessage name="fast_period" component="div" className="alert alert-danger" />
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="fast_period" />
                      )}

                      {indicator && indicators[indicator].slow_period_visible ? (
                        <div className="form-group row">
                          <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Slow Period</label>
                          <div className="col-sm-10">
                            <Field name="slow_period" className="form-control" type="number" />
                            <ErrorMessage name="slow_period" component="div" className="alert alert-danger" />
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="chart_period" />
                      )}

                      {indicator && indicators[indicator].signal_period_visible ? (
                        <div className="form-group row">
                          <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">Signal Period</label>
                          <div className="col-sm-10">
                            <Field name="signal_period" className="form-control" type="number" />
                            <ErrorMessage name="signal_period" component="div" className="alert alert-danger" />
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="signal_period" />
                      )}

                      {indicator && indicators[indicator].period_visible ? (
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">Period</label>
                          <div className="col-sm-10">
                            <Field name="period" className="form-control" type="number" />
                            <ErrorMessage name="period" component="div" className="alert alert-danger" />
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="period" />
                      )}

                      {indicator && indicators[indicator].ma_type_visible ? (
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label">MA-Type</label>
                          <div className="col-sm-10">
                            <Field className="custom-select rounded-0" as="select" name="ma_type">
                              <option value="ema">EMA</option>
                              <option value="sma">SMA</option>
                              <option value="wma">WMA</option>
                              <option value="tema">TEMA</option>
                              <option value="trima">TRIMA</option>
                            </Field>
                          </div>
                        </div>
                      ) : (
                        <Field type="hidden" name="ma_type" />
                      )}

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Keep signal for</label>
                        <div className="col-sm-10">
                          {/* https://github.com/jaredpalmer/formik/issues/1191 */}
                          <Field className="custom-select rounded-0" as="select" name="keep_signal" onChange={event => setFieldValue(event.target.name,parseInt(event.target.value, 10))}>
                            <option value="0">Do not keep signal</option>
                            <option value="1">1 candle</option>
                            <option value="2">2 candles</option>
                            <option value="3">3 candles</option>
                            <option value="4">4 candles</option>
                            <option value="5">5 candles</option>
                            <option value="6">6 candles</option>
                            <option value="7">7 candles</option>
                            <option value="8">8 candles</option>
                            <option value="9">9 candles</option>
                            <option value="10">10 candles</option>
                          </Field>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Mandatory</label>
                        <div className="col-sm-10">
                          <Field className="custom-select rounded-0" as="select" name="mandatory" onChange={event => setFieldValue(event.target.name,parseInt(event.target.value, 10))}>
                            <option value="1">yes</option>
                            <option value="0">no</option>
                          </Field>
                        </div>
                      </div>

                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={() => { if (formRef.current) formRef.current.handleSubmit() }}><i className="fas fa-save"> </i> Save</Button>
        <Button variant="secondary" onClick={handleIndicatorFormModalClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
