import { useQueryClient, useMutation } from "react-query";
import { updateStrategy } from "../services/strategy";

export const useUpdateStrategyMutation = (id) => {
  const queryClient = useQueryClient()

  return useMutation((formPayload) => {
    return updateStrategy(id, formPayload);
  }, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['strategies', { id: variables.id }], data)
    },
  });
};