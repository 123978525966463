import { create } from 'zustand';
import { persist } from 'zustand/middleware';

function parseJwt (token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const useStore = create(
  persist(
    (set) => ({
      id: '',
      firstname: '',
      lastname: '',
      role: '',
      token: '',
      isAuthenticated: false,
      setToken: (token) => set((state) => ({ 
        id: parseJwt(token).userid,
        firstname: parseJwt(token).firstname,
        lastname: parseJwt(token).lastname,
        role: parseJwt(token).role,
        isAuthenticated: true,
        token: token 
      })),
      clear: () => set((state) => ({ 
        id: '',
        firstname: '',
        lastname: '',
        role: '',
        isAuthenticated: false,
        token: '' 
      })),
    }),
    {
      name: 'lilith-store'
    }
  )
);

export {
  parseJwt,
  useStore
}