import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useStore, parseJwt } from './store';
import { refreshToken } from './services/auth';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Strategies from './pages/Strategies';
import StrategyEdit from './pages/Strategy';
import Bots from './pages/Bots';
import Stats from './pages/Stats';
import Charts from './pages/Charts';
import History from './pages/History';
import Notfound from './pages/NotFound';

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = useStore(state => state.isAuthenticated);

  let location = useLocation()
  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  )
}

export default function App() {
  const isAuthenticated = useStore(state => state.isAuthenticated);
  const token = useStore(state => state.token);
  const setToken = useStore(state => state.setToken);

  useEffect(() => {
    if (isAuthenticated) {
      const interval = setInterval(
        () => {
          const parsedToken = parseJwt(token);
          const difference = parsedToken.exp - Math.floor(Date.now() / 1000)
          if(difference > 0 && difference < (60 * 5)) {
            console.log("Refreshing token...");
            const newToken = refreshToken();
            setToken(newToken.token);
          }
        },
        10 * 60 * 1000
      );
      return () => clearInterval(interval);
    }
  },
  [isAuthenticated, token, setToken]
  );
  
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />}></Route>
          <Route path='/' element={<ProtectedRoute><Dashboard /></ProtectedRoute>}></Route>
          <Route path='/strategies' element={<ProtectedRoute><Strategies /></ProtectedRoute>}></Route>
          <Route path='/strategies/add' element={<ProtectedRoute><StrategyEdit /></ProtectedRoute>}></Route>
          <Route path='/strategies/edit/:id' element={<ProtectedRoute><StrategyEdit /></ProtectedRoute>}></Route>
          <Route path='/bots' element={<ProtectedRoute><Bots /></ProtectedRoute>}></Route>
          <Route path='/stats' element={<ProtectedRoute><Stats /></ProtectedRoute>}></Route>
          <Route path='/charts' element={<ProtectedRoute><Charts /></ProtectedRoute>}></Route>
          <Route path='/history' element={<ProtectedRoute><History /></ProtectedRoute>}></Route>

          <Route path='*' element={<Notfound />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
